import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, effect, inject, runInInjectionContext, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable, delay, throwError } from 'rxjs';
 
 
@Injectable({
  providedIn: 'root',
})
export class PostsService {
  private http = inject(HttpClient);
 
  get(userId?: number): Observable<any[]> {
    if (userId == 100) {
      return throwError(() => new Error('User not found'));
    }
   let x=this.http
    .get<any[]>('https://jsonplaceholder.typicode.com/posts', {
      params: {
        ...(userId ? { userId: userId.toString() } : {}),
      },
    })
    .pipe(delay(2000));
     
    return x
  }
  getdatawithSignal(observar,obj):Signal<any> {

     let x =toSignal(observar, obj);
     return x

  }
}