/* src/app/shared/component/skelton-frame/skelton-frame.component.scss */
.item {
  width: auto;
  height: auto;
  margin: 10px auto;
  padding: 10px;
}
.fb-item {
  width: auto;
  height: auto;
  margin: 10px auto;
  padding: 10px;
}
.gravatar {
  width: 100px;
  height: 90px;
}
.gravatar-title {
  width: 500px;
  padding: 10px;
  height: 80px;
}
.first-section-wrapper,
.second-section-wrapper {
  width: 100%;
  height: auto;
  flex: 1;
}
.first-section-wrapper {
  display: flex;
  justify-content: center;
}
.second-section-wrapper {
  margin-top: 30px;
}
.wrapper {
  width: 100%;
  flex: 1;
}
.item-with-background {
  background: #121212;
  padding: 10px;
}
.loader {
  line-height: 1;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background: rgb(239, 241, 246) no-repeat;
  background-color: rgb(239, 241, 246);
  line-height: 1;
  overflow: hidden;
  position: relative;
}
/*# sourceMappingURL=skelton-frame.component-SK2IMV7P.css.map */
