import { CommonModule } from '@angular/common';
import { Component, OnInit,Input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-skelton-frame',
  templateUrl: './skelton-frame.component.html',
  styleUrls: ['./skelton-frame.component.scss'],
  standalone:true,
  imports:[NgxSkeletonLoaderModule,CommonModule]
})
export class SkeltonFrameComponent  implements OnInit {
  @Input() type: string = 'simpleCard';

  constructor() { }

  ngOnInit(): void {
  }

}
