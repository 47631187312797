<div class="fb-item" *ngIf="type=='simpleCard'">
    <div class="first-section-wrapper">
      <div class="gravatar">
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{ width: '80px', height: '80px' }"
        >
        </ngx-skeleton-loader>
      </div>
     
    </div>
    <div class="second-section-wrapper">
  
      <div class="wrapper">
        <ngx-skeleton-loader
        count='3'
          [theme]="{
            width: '90%',
            'border-radius': '0',
            height: '15px',
            'margin-bottom': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
  
    </div>
  </div>




  <div class="fb-item" *ngIf="type=='content'">
    <div class="first-section-wrapper">
      <div class="wrapper">
        <ngx-skeleton-loader
       
          [theme]="{
            width: '50%',
            'border-radius': '0',
            height: '20px',
            'margin-bottom': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
     
    </div>
    <div class="second-section-wrapper">
  
      <div class="wrapper">
        <ngx-skeleton-loader
        count='3'
          [theme]="{
            width: '100%',
            height: '15px',
            'margin-bottom': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
  
    </div>
  </div>


  <div  *ngIf="type=='sideMenu'">
 
  
        <ngx-skeleton-loader
        count='4'
          [theme]="{
            width: '100%',
            'border-radius': '0',
            height: '56px',
            'margin-bottom': '10px'
          }"
        ></ngx-skeleton-loader>
  
  </div>

  <div  *ngIf="type=='ads'">
 
  
    <ngx-skeleton-loader
    count='4'
      [theme]="{
        width: '100%',
        'border-radius': '0',
        height: '188px',
        'margin-bottom': '10px'
      }"
    ></ngx-skeleton-loader>

</div>


<div  *ngIf="type=='footer'" class="transparentBG">
 
  
  <ngx-skeleton-loader
  count='4'
    [theme]="{
      width: '100%',
      'border-radius': '0',
      height: '20px',
      'margin-bottom': '10px'
    }"
  ></ngx-skeleton-loader>

</div>

<div  *ngIf="type=='footerTitle'" class="transparentBG">
 
  
  <ngx-skeleton-loader
  count='1'
    [theme]="{
      width: '100%',
      'border-radius': '0',
      height: '24px',
      'margin-bottom': '10px'
    }"
  ></ngx-skeleton-loader>

</div>


<div  *ngIf="type=='headerTitle'"  class="skeleton-loader-mb">
 
  
  <ngx-skeleton-loader
  count='1'
    [theme]="{
      width: '120px',
      'border-radius': '0',
      height: '24px',
      'margin-bottom': '10px'
    }"
  ></ngx-skeleton-loader>

</div>


<div class="fb-item" *ngIf="type=='magazin'"  >
  <div class="first-section-wrapper">
    <div class="gravatar">
      <ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px','border-radius': '10px', height: '40px' }">
      </ngx-skeleton-loader>
    </div>
    <div class="gravatar-title">
      <div>
        <ngx-skeleton-loader
        count='2'
          [theme]="{
            width: '120px',
            'border-radius': '0',
            height: '15px',
            'margin-bottom': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
 
    </div>
  </div>
  <div class="second-section-wrapper">
    <div class="wrapper">
      <ngx-skeleton-loader
        [theme]="{
          width: '100%',
          'border-radius': '0',
          height: '48px',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>


  </div>
</div>


<div class="fb-item"   *ngIf="type=='servicesGiud'">
  <div class="">
   
    <div >
      <div>
        <ngx-skeleton-loader
          [theme]="{
            width: '170px',
            'border-radius': '0',
            height: '20px',
            'margin-bottom': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader
                [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
      ></ngx-skeleton-loader>
      
      </div>
      <div>
        <ngx-skeleton-loader
                [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
      ></ngx-skeleton-loader>
      
      </div>
      <div>
        <ngx-skeleton-loader
                [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
      ></ngx-skeleton-loader>
      
      </div>
    </div>
  </div>
  <div>
   
    <div class=""><ngx-skeleton-loader count="5" appearance="circle"> </ngx-skeleton-loader></div>
    <div class=""><ngx-skeleton-loader count="4" appearance="circle"
      
      [theme]="{ width: '150px','border-radius': '60px', height: '45px' }"
      > </ngx-skeleton-loader></div>

      <ngx-skeleton-loader
      count="3"
                [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
      ></ngx-skeleton-loader>
  </div>
</div>

<div class="fb-item" *ngIf="type=='electronicAccept'">
  <div class="">
   
    <div >
      <div>
        <ngx-skeleton-loader
          [theme]="{
            width: '170px',
            'border-radius': '0',
            height: '20px',
            'margin-bottom': '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class='row'>
        <div class='col-md-4' >
          <ngx-skeleton-loader
          [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
></ngx-skeleton-loader>
        </div>
        <div class='col-md-4' >
          <ngx-skeleton-loader
          [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
></ngx-skeleton-loader>
        </div>
        <div class='col-md-4' >
          <ngx-skeleton-loader
          [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
></ngx-skeleton-loader>
        </div>
        <div class='col-md-12' >
          <ngx-skeleton-loader
          count="3"
          [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
></ngx-skeleton-loader>
        </div>
      
      </div>
    
    </div>
  </div>
  <div>
   
    <div class=""><ngx-skeleton-loader count="1" appearance="circle"
      
      [theme]="{ width: '150px','border-radius': '60px', height: '45px' }"
      > </ngx-skeleton-loader>
    
    

    </div>


  </div>
</div>

<div class="fb-item"  *ngIf="type=='vedio'">
  <div class="">
   
    <div >
      <ngx-skeleton-loader
      [theme]="{
        width: '100%',
        'border-radius': '0',
        height: '248px',
        'margin-bottom': '10px'
      }"
    ></ngx-skeleton-loader>
      <div class='row'>
        <div class='col-md-12' >
          <ngx-skeleton-loader
          [theme]="{ width: '100%','border-radius': '0', height: '20px' }"
></ngx-skeleton-loader>
        </div>
        <div class='col-md-6' >
          <ngx-skeleton-loader
          [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
></ngx-skeleton-loader>
        </div>
  
        <div class='col-md-6' >
          <ngx-skeleton-loader
          [theme]="{ width: '100%','border-radius': '0', height: '15px' }"
></ngx-skeleton-loader>
        </div>
  
      </div>
    
    </div>
  </div>

</div>
<div class="fb-item m-0 skeleton-loader-mb"  *ngIf="type=='img'">
   
  <div class="">
   
    <div >
      <ngx-skeleton-loader
      [theme]="{
        width: '100%',
        'border-radius': '0',
        height: '248px',
        'margin-bottom': '10px'
      }"
    ></ngx-skeleton-loader>
      
    
    </div>
  </div>
    
    

</div>



<div class="item" *ngIf="type=='Innovations'">
  <ngx-skeleton-loader
    count="2"
    [theme]="{  height: '45px',width: '140px', 'border-radius': '10px' }"
  ></ngx-skeleton-loader>
</div>






<div class="fb-item" *ngIf="type=='news'">
  <div class="first-section-wrapper">
    <div class="wrapper">
      <ngx-skeleton-loader
     
        [theme]="{
          width: '100%',
          'border-radius': '0',
          height: '200px',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>
   
  </div>
  <div class="first-section-wrapper">
    <div class="wrapper">
      <ngx-skeleton-loader
     
        [theme]="{
          width: '50%',
          'border-radius': '0',
          height: '20px',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>
   
  </div>
  <div class="second-section-wrapper">

    <div class="wrapper">
      <ngx-skeleton-loader
      count='3'
        [theme]="{
          width: '100%',
          'border-radius': '0',
          height: '15px',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>

  </div>
</div>

<div class="fb-item" *ngIf="type=='faq'">


  <div class="second-section-wrapper">

    <div class="wrapper">
      <ngx-skeleton-loader
      count='4'
        [theme]="{
          width: '100%',
          'border-radius': '0',
          height: '60px',
          'margin-bottom': '10px'
        }"
      ></ngx-skeleton-loader>
    </div>

  </div>
</div>
